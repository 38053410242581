import { Typography, Card, CardContent, CardHeader, List, Stack } from '@mui/material';
import { SiteDetail } from 'api/site';
import { useTranslation } from 'react-i18next';
import { CopyButton } from './CopyButton';
import { formatDataSize } from 'utils/number';
import { CardList } from '../base/CardList';
import { Flag } from '../base/Flag';

interface Props {
  readonly siteDetails?: SiteDetail;
}

export function SiteDetailsCard({ siteDetails }: Props) {
  const { t } = useTranslation();

  const location = siteDetails?.location?.split(' - ') || ['', ''];

  const items = [
    {
      id: t('ftp_address'),
      value: siteDetails?.ftp_ip_address,
      enableCopy: true,
    },
    {
      id: t('sftp_username'),
      value: siteDetails?.sftp_username,
      enableCopy: true,
    },
    {
      id: t('cdn_url'),
      value: siteDetails?.rocket_url,
      enableCopy: true,
    },
    {
      id: t('location'),
      content: (
        <Stack direction="row" gap={1} alignItems="center">
          <Flag code={location[0]} size="s" />
          {typeof location[1] === 'string' ? location[1] : siteDetails?.location}
        </Stack>
      ),
      enableCopy: false,
    },
    {
      id: t('disk_usage'),
      value: formatDataSize(siteDetails?.disk_usage),
      enableCopy: false,
    },
  ];

  return (
    <Card component="section">
      <CardHeader title={t('site_information')} />
      <dl>
        <CardList
          loading={!siteDetails}
          items={items}
          renderItem={item => (
            <Stack>
              <Typography variant="body2" color="greys.500" component="dt">
                {item.id}
              </Typography>
              <Typography variant="body2" fontWeight={600} component="dd">
                {item.content || item.value}
              </Typography>
            </Stack>
          )}
          renderAction={item => (item.enableCopy ? <CopyButton value={item.value || ''} /> : null)}
        />
      </dl>
    </Card>
  );
}
