import { Button, Card, CardHeader, CardActions, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ChangeDomainDialog } from './ChangeDomainDialog';
import { SiteDomain, useDeleteDomain, useSiteDomains } from 'api/domain';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import Plus from '../icons/Plus.svg?react';
import PencilEdit from '../icons/PencilEdit.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { useDebouncedInputProps } from 'component/hooks/useDebouncedInput';
import { PaginationControls } from '../base/PaginationControls';
import { SearchInput } from '../base/SearchInput';
import { DropdownMenu } from '../base/DropdownMenu';
import { CardList } from '../base/CardList';
import { IconWrapper } from '../base/IconWrapper';

interface DomainListCardProps {
  readonly domain: string;
}

const PrimaryDomainAction = ({
  domain,
  onChange,
}: {
  readonly domain: string;
  readonly onChange: () => void;
}) => {
  const canChangeDomain = !['onrocket.site', 'wpdns.site'].some(url => domain.endsWith(url));

  return canChangeDomain ? (
    <Button variant="text" onClick={onChange}>
      <IconWrapper color="greys.500" size="s">
        <PencilEdit />
      </IconWrapper>
    </Button>
  ) : null;
};

const AliasDomainAction = ({
  onChange,
  onDelete,
}: {
  readonly onChange: () => void;
  readonly onDelete: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <DropdownMenu
      actions={[
        {
          icon: <PencilEdit />,
          label: t('edit'),
          onClick: onChange,
        },
        {
          icon: <DeleteDustbin />,
          label: t('delete'),
          onClick: onDelete,
          color: 'reds.500',
        },
      ]}
    />
  );
};

export function DomainsListCard({ domain }: DomainListCardProps) {
  const { siteId = '' } = useParams<{ siteId: string }>();
  const { t } = useTranslation();

  const [isChangeDialogOpen, setIsChangeDialogOpen] = useState(false);
  const [domainToDelete, setDomainToDelete] = useState<SiteDomain | null>(null);

  const deleteDomain = useDeleteDomain(siteId);

  const listState = usePaginatedListState({ urlStatePrefix: 'domains' });
  const searchInputProps = useDebouncedInputProps(listState.params.search, listState.setSearch);

  const domains = useSiteDomains(siteId, listState);

  const additionalDomains = domains.data?.data.result?.additional_domains || [];
  const totalRowCount = domains.data?.data.metadata?.total || 0;

  const processedDomains = additionalDomains.map((domain: SiteDomain) => ({
    id: domain.id!,
    type: 'alias',
    domain: domain.domain,
  }));

  if (listState.params.page === 1) {
    processedDomains.unshift({
      id: 0,
      type: 'primary',
      domain,
    });
  }

  return (
    <>
      <Card sx={{ display: 'flex', flexDirection: 'column' }} component="section">
        <CardHeader
          title={t('domain_plural')}
          action={
            <>
              <SearchInput {...searchInputProps} placeholder={t('search_domains')} />
              <Button color="primary" variant="contained" startIcon={<Plus />}>
                {t('add_alias')}
              </Button>
            </>
          }
        />
        <CardList
          loading={domains.isLoading || !domain}
          items={processedDomains}
          renderItem={item => (
            <Stack>
              <Typography variant="body2" color="greys.500">
                {t(item.type)}
              </Typography>
              <Typography variant="body2" fontWeight={600}>
                {item.domain}
              </Typography>
            </Stack>
          )}
          renderAction={item =>
            item.type === 'primary' ? (
              <PrimaryDomainAction
                domain={item.domain!}
                onChange={() => setIsChangeDialogOpen(true)}
              />
            ) : (
              <AliasDomainAction
                // TODO implement editing of alias domains
                onChange={() => console.error('edit not implemented')}
                onDelete={() => setDomainToDelete(item)}
              />
            )
          }
        />
        <CardActions sx={{ marginTop: 'auto' }}>
          <PaginationControls state={listState} totalRowCount={totalRowCount} />
        </CardActions>
      </Card>
      <ConfirmationDialog
        onClose={() => setDomainToDelete(null)}
        open={Boolean(domainToDelete)}
        onConfirm={async () => {
          await deleteDomain.mutateAsync({ id: domainToDelete?.id ?? 0 });
        }}
        title={t('delete_domain')}
        description={t('delete_additional_domain', {
          title: domainToDelete?.domain,
        })}
        confirmText={t('delete')}
        confirmColor="error"
      />
      {isChangeDialogOpen ? (
        <ChangeDomainDialog
          siteId={siteId}
          domain={domain}
          onClose={() => setIsChangeDialogOpen(false)}
        />
      ) : null}
    </>
  );
}
