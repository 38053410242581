import { useState } from 'react';
import { Button, Box, Stack, CircularProgress, styled, Skeleton } from '@mui/material';
import {
  useCreateStaging,
  SiteDetail,
  useDeleteStaging,
  usePublishStaging,
  useGetTaskStatus,
  useSiteDetails,
} from 'api/site';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { useTranslation } from 'react-i18next';
import { delay } from 'utils/delay';
import { Select } from '../base/forms/Select';
import ActionCard from './ActionCard';
import { linkHelper } from 'linkHelper';
// icons
import PlayCircle from '../icons/PlayCircle.svg?react';
import GitFork from '../icons/GitFork.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import UploadUp from '../icons/UploadUp.svg?react';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';
import { RoleGuard } from 'component/base/RoleGuard';
import { useBuildSelectOptions } from 'component/hooks/useBuildSelectOptions';

type CurrentTask = 'creatingStaging' | 'deletingStaging' | 'publishingStaging';

interface Props {
  readonly siteDetails?: SiteDetail;
  readonly isLoading: boolean;
}

function useGetStatusPollingAfterCreate(siteId: number) {
  const getTaskStatus = useGetTaskStatus(siteId.toString());

  return async (taskId: string | undefined) => {
    if (!taskId) {
      throw new Error('Task ID is missing');
    }
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const result = await getTaskStatus.mutateAsync(taskId);

      const taskStatus = result.data.result?.[0]?.task_status;

      if (taskStatus === 'DONE') {
        return;
      } else if (taskStatus === 'ERROR') {
        throw new Error('Task failed');
      }

      await delay(10_000);
    }
  };
}

function getMode(siteDetails: SiteDetail, currentSiteId: number) {
  if (typeof siteDetails.production === 'number') {
    if (siteDetails.production === currentSiteId) {
      return 'production';
    }
    return 'staging';
  }

  if (
    typeof siteDetails.staging?.staging_id === 'undefined' ||
    (typeof siteDetails.staging?.staging_id === 'number' &&
      siteDetails.staging.staging_id !== currentSiteId)
  ) {
    return 'production';
  }

  return 'staging';
}

const AdornmentBox = styled(Box)(({ theme }) => ({
  marginTop: '5px',
  marginRight: '5px',
  color: theme.palette.greys[500],
  '& svg': {
    height: '16px',
    width: '16px',
  },
}));

export default function CurrentMode({ siteDetails, isLoading }: Props) {
  const params = useParams<{ siteId: string }>();
  const [currentTask, setCurrentTask] = useState<CurrentTask | null>(null);
  const siteId = Number(params.siteId);
  const createStaging = useCreateStaging(siteId.toString());
  const productionId = siteDetails?.production?.toString() ?? '';
  const deleteStaging = useDeleteStaging(productionId);
  const publishStaging = usePublishStaging(productionId);
  const getProductionStatusPolling = useGetStatusPollingAfterCreate(Number(productionId));
  const getStagingStatusPolling = useGetStatusPollingAfterCreate(siteId);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const value = siteDetails ? getMode(siteDetails, siteId) : 'production';
  const [showConfirmation, setShowConfirmation] = useState<'delete' | 'publish' | null>(null);
  const { buildOptions } = useBuildSelectOptions();

  const { refetch: siteDetailsRefetch } = useSiteDetails(
    {
      id: siteId.toString(),
    },
    { enabled: false }
  );

  const isBillingAdmin = useCheckUserRole('billing_admin');

  // TODO - confirm with Josip
  if (isLoading) {
    return <Skeleton variant="rounded" height="80px" />;
  }

  if (!siteDetails) {
    return null;
  }

  if (currentTask === 'creatingStaging') {
    return (
      <ActionCard
        icon={<CircularProgress size={24} />}
        paletteColor="greys"
        title={t('creating_staging')}
        description={t('it_can_take_few_seconds')}
      />
    );
  }

  if (!siteDetails.staging?.staging_id && !siteDetails.production) {
    return (
      <ActionCard
        actions={
          <RoleGuard roles={['billing_admin']} type="block">
            <Button
              variant="contained"
              color="tertiary"
              fullWidth
              startIcon={<GitFork />}
              onClick={async () => {
                try {
                  setCurrentTask('creatingStaging');
                  const result = await createStaging.mutateAsync();
                  await getStagingStatusPolling(result.data.result?.task_id);
                  const siteDetailsResult = await siteDetailsRefetch();
                  enqueueSnackbar(t('staging_create'), {
                    key: 'stagingCreate',
                    variant: 'success',
                  });
                  navigate(
                    linkHelper.newDesign.sites.siteDetails.getLink({
                      siteId: String(siteDetailsResult.data?.data.result?.staging?.staging_id),
                    })
                  );
                } finally {
                  setCurrentTask(null);
                }
              }}
            >
              {t('create_staging')}
            </Button>
          </RoleGuard>
        }
        paletteColor="oranges"
        icon={<PlayCircle />}
        title={t('production_environment')}
        description={t('production_environment_description')}
      />
    );
  }

  return (
    <>
      {showConfirmation === 'delete' && (
        <ConfirmationDialog
          confirmColor="error"
          title={t('delete_staging')}
          description={t('delete_staging_confirmation_description')}
          onClose={() => setShowConfirmation(null)}
          onConfirm={async () => {
            try {
              setCurrentTask('deletingStaging');
              await deleteStaging.mutateAsync();
              enqueueSnackbar(t('staging_delete'), {
                key: 'stagingDelete',
                variant: 'success',
              });
              navigate(
                linkHelper.newDesign.sites.siteDetails.getLink({
                  siteId: String(siteDetails.production),
                })
              );
            } finally {
              setCurrentTask(null);
            }
          }}
        />
      )}
      {showConfirmation === 'publish' && (
        <ConfirmationDialog
          title={t('publish_staging_confirmation_title')}
          description={t('publish_staging_confirmation_description')}
          onClose={() => setShowConfirmation(null)}
          onConfirm={async () => {
            try {
              setCurrentTask('publishingStaging');
              const result = await publishStaging.mutateAsync();
              await getProductionStatusPolling(result.data.result?.task_id);
              const siteDetailsResult = await siteDetailsRefetch();
              enqueueSnackbar(t('staging_publish'), {
                key: 'stagingPublish',
                variant: 'success',
              });
              navigate(
                linkHelper.newDesign.sites.siteDetails.getLink({
                  siteId: String(siteDetailsResult.data?.data.result?.production ?? ''),
                })
              );
            } finally {
              setCurrentTask(null);
            }
          }}
        />
      )}
      <ActionCard
        paletteColor={siteDetails.staging ? 'oranges' : 'greys'}
        leftContent={
          <Select
            disabled={isBillingAdmin}
            aria-label={t('site_environment')}
            variant={value === 'production' ? 'outlined' : 'filled'}
            startAdornment={
              value === 'production' ? (
                <AdornmentBox>
                  <PlayCircle />
                </AdornmentBox>
              ) : (
                <AdornmentBox>
                  <GitFork />
                </AdornmentBox>
              )
            }
            value={value}
            options={buildOptions('production', 'staging')}
            onChange={(val: 'staging' | 'production') => {
              if (val === 'staging') {
                navigate(
                  linkHelper.newDesign.sites.siteDetails.getLink({
                    siteId: String(siteDetails?.staging?.staging_id ?? ''),
                  })
                );
              } else if (val === 'production') {
                navigate(
                  linkHelper.newDesign.sites.siteDetails.getLink({
                    siteId: String(siteDetails?.production ?? ''),
                  })
                );
              }
            }}
          />
        }
        description={siteDetails.staging ? t('production_description') : t('staging_description')}
        actions={
          siteDetails.staging ? null : (
            <RoleGuard roles={['billing_admin']} type="block">
              <Stack gap={4} direction={{ xs: 'column', sm: 'row' }}>
                <Button
                  variant="text"
                  sx={theme => ({
                    color: theme.palette.reds[500],
                  })}
                  disabled={
                    currentTask === 'deletingStaging' || currentTask === 'publishingStaging'
                  }
                  startIcon={<DeleteDustbin />}
                  onClick={() => {
                    setShowConfirmation('delete');
                  }}
                >
                  {t('delete_staging')}
                </Button>
                <Button
                  sx={{
                    minWidth: '150px',
                  }}
                  variant="contained"
                  startIcon={<UploadUp />}
                  onClick={() => {
                    setShowConfirmation('publish');
                  }}
                  disabled={
                    currentTask === 'deletingStaging' || currentTask === 'publishingStaging'
                  }
                >
                  {t('publish_to_staging')}
                </Button>
              </Stack>
            </RoleGuard>
          )
        }
      />
    </>
  );
}
