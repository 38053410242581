import { Box, Stack } from '@mui/material';
import { SiteDetail } from 'api/site';
import { SiteActivityCard } from './SiteActivityCard';
import { FtpAccounts } from './FtpAccounts';
import { SiteUserList } from './SiteUserList';
import ReadyToGoLiveCard from './ReadyToGoLiveCard';
import { SiteDetailsCard } from './SitesDetailsCard';
import { DomainsListCard } from './DomainsListCard';
import { useTranslation } from 'react-i18next';

interface Props {
  readonly siteDetails?: SiteDetail;
}

export function SiteDetailsOverview({ siteDetails }: Props) {
  const { t } = useTranslation();

  return (
    <Stack gap="var(--dashboard-gap)">
      <ReadyToGoLiveCard siteDetails={siteDetails} />
      <SiteActivityCard title={t('site_activity')} />
      <Box
        sx={{
          display: 'grid',
          gap: 'var(--dashboard-gap)',
          gridTemplateColumns: {
            sm: '1fr',
            lg: '1fr 1fr',
          },
        }}
      >
        <SiteDetailsCard siteDetails={siteDetails} />
        <DomainsListCard domain={siteDetails?.domain ?? ''} />
        <FtpAccounts domain={siteDetails?.domain ?? ''} />
        <SiteUserList siteDetails={siteDetails} />
      </Box>
    </Stack>
  );
}
