import RocketShipSolid from '../icons/RocketShipSolid.svg?react';
import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Checkbox,
} from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import isFQDN from 'validator/lib/isFQDN';
import {
  useSetMainDomain,
  useUpdateSSLValidation,
  VerificationRecord,
  useForceValidationRecheck,
  useUpdateGoLiveMode,
} from 'api/domain';
import { CopyButton } from '../partial/CopyButton';
import { TextField } from '../base/forms/TextField';
import { Trans, useTranslation } from 'react-i18next';
import { getMainDomainInfo } from 'utils/site';
import { components } from 'openapi-types';
import Copy from '../icons/Copy.svg?react';
import CopyValueCard from './CopyValueCard';
import WizardDialog, { WizardFormActions, WizardFormContent } from '../base/dialogs/WizardDialog';
import { LoadingButton } from '@mui/lab';
import ActionCard from './ActionCard';
import CheckboxChecked from '../icons/CheckboxChecked.svg?react';
import { NoteCard } from '../base/NoteCard';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { SiteDetail } from 'api/site';

const DNS_PROVIDER_CLOUDFLARE: string = 'cloudflare';

interface SetupInstructionsProps {
  readonly instructions: {
    key: string;
    data: any;
    components?: { readonly [tagName: string]: React.ReactElement };
  }[];
  testId: string;
}

function SetupInstructions({ instructions, testId }: SetupInstructionsProps) {
  return instructions.map(({ key, data, components }, index) => (
    <Stack
      data-testid={`${testId}-${index + 1}`}
      key={data}
      gap={2}
      direction="row"
      alignItems="baseline"
    >
      <Chip
        label={index + 1}
        sx={{
          height: '24px',
          '& > .MuiChip-label': {
            padding: '0 9.5px',
          },
          backgroundColor: 'greys.100',
        }}
      />
      <Typography variant="body2" color="greys.900" sx={{ lineHeight: '2rem' }}>
        <Trans i18nKey={key} values={data} components={components} />
      </Typography>
    </Stack>
  ));
}

interface DNSRecordsTableProps {
  readonly records: components['schemas']['ValidationRecord'][];
  readonly label: string;
  readonly hideTTLColumn?: boolean;
}

function DNSRecordsTable({ records, label, hideTTLColumn = false }: DNSRecordsTableProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={3}>
      <Typography variant="body2" color="greys.500">
        {t('example_how_it_make_look')}
      </Typography>
      <TableContainer>
        <Table
          aria-label={label}
          sx={{
            gridTemplateColumns: hideTTLColumn ? '10% 1fr 1fr' : '10% 1fr 1fr 1fr',
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell align="left">{t('type')}</TableCell>
              <TableCell align="left">{t('name')}</TableCell>
              <TableCell align="left">{t('value')}</TableCell>
              {!hideTTLColumn && <TableCell align="left">{t('ttl')}</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map(row => (
              <TableRow key={(row.type ?? '') + (row.name ?? '')}>
                <TableCell align="left">{row.type?.toUpperCase()}</TableCell>
                <TableCell align="left">
                  {row.name ?? ''}
                  <CopyButton hideText value={row.name ?? ''} />
                </TableCell>
                <TableCell align="left">
                  {row.value ?? ''}
                  <CopyButton hideText value={row.value ?? ''} />
                </TableCell>
                {!hideTTLColumn && <TableCell align="left">{t('automatic')}</TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

interface StepProps {
  readonly siteDetails?: SiteDetail;
  readonly setActiveStep: (step: number) => unknown;
  readonly onClose: () => unknown;
}

function UpdateDns({ siteDetails, onClose }: Pick<StepProps, 'siteDetails' | 'onClose'>) {
  const { t } = useTranslation();

  const hardcodedIP = '104.19.154.92';
  const siteId = siteDetails?.id?.toString() ?? '';
  const updateGoLiveMode = useUpdateGoLiveMode(siteId);
  const forceValidationRecheck = useForceValidationRecheck(siteId, true);
  const rocketUrl = siteDetails?.rocket_url ?? '';

  const { domainType, baseDomain, subdomain, dnsProvider } = getMainDomainInfo(siteDetails);

  let instructions: SetupInstructionsProps['instructions'];
  let dnsRecords: DNSRecordsTableProps['records'];

  if (domainType === 'subdomain') {
    instructions = [
      { key: 'update_dns_instructions_part_three_subdomain', data: { subdomain } },
      { key: 'update_dns_instructions_part_four', data: { value: rocketUrl } },
    ];

    dnsRecords = [{ type: 'cname', name: subdomain ?? undefined, value: rocketUrl }];
  } else {
    // Apex will have IP (non-cloudflare DNS) or rocket url (cloudflare DNS)
    const apexValue: string = dnsProvider !== DNS_PROVIDER_CLOUDFLARE ? hardcodedIP : rocketUrl;
    const apexType: components['schemas']['ValidationRecord']['type'] =
      dnsProvider !== DNS_PROVIDER_CLOUDFLARE ? 'a' : 'cname';

    instructions = [
      {
        key: 'update_dns_instructions_part_three_domain',
        data: { type: apexType.toUpperCase(), baseDomain },
      },
      {
        key: 'update_dns_instructions_part_four_new',
        data: {},
        components: { copyValueBubble: <CopyValueCard value={apexValue} /> },
      },
      { key: 'update_dns_instructions_part_five_domain', data: { baseDomain } },
    ];

    dnsRecords = [
      { type: apexType, name: '@', value: apexValue },
      { type: 'cname', name: 'www', value: rocketUrl },
    ];
  }

  return (
    <>
      <WizardFormContent>
        <Stack gap={4}>
          <Typography variant="body1" fontWeight="600">
            {t('update_dns')}
          </Typography>
          <Typography variant="body2" color="greys.500">
            {t('update_dns_instructions_part_one')}
          </Typography>
          <SetupInstructions instructions={instructions} testId="update-dns-step" />
          <DNSRecordsTable records={dnsRecords} label="Update DNS Example Table" />
        </Stack>
      </WizardFormContent>
      <WizardFormActions>
        <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <Stack gap={3} direction="row" flex="none">
            <LoadingButton
              size="large"
              onClick={async () => {
                try {
                  await forceValidationRecheck.mutateAsync();
                } catch (e) {
                  console.error(e);
                }
                await updateGoLiveMode.mutateAsync({ hide_go_live: 1 });
              }}
              loading={updateGoLiveMode.isPending}
              variant="contained"
            >
              {t('finish')}
            </LoadingButton>
            <Button size="large" onClick={onClose}>
              {t('cancel')}
            </Button>
          </Stack>
          <FormControlLabel
            label={t('dont_display_reminder_anymore')}
            control={
              <Checkbox
                icon={<span />}
                checkedIcon={<CheckboxChecked />}
                defaultChecked
                onChange={async e => {
                  const val = e.target.checked;
                  await updateGoLiveMode.mutateAsync({ hide_go_live: val ? 1 : 0 });
                }}
              />
            }
          />
        </Stack>
      </WizardFormActions>
    </>
  );
}

function ConfigureSSL({ siteDetails, setActiveStep, onClose }: StepProps) {
  const siteId = siteDetails?.id?.toString() ?? '';
  const updateSSLValidation = useUpdateSSLValidation(siteId);
  const forceValidationRecheck = useForceValidationRecheck(siteId);

  const { baseDomain, subdomain, validationRecords, sslStatus } = getMainDomainInfo(siteDetails);

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    if (sslStatus === 'active') {
      setActiveStep(3);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    const messages: string[] = forceValidationRecheck.data?.data?.messages ?? [];

    if (messages.includes('Domain is already active')) {
      setActiveStep(3);
    }

    if (forceValidationRecheck.status === 'success') {
      enqueueSnackbar(
        `${t('status_pending_validation')} - ${t('please_wait_or_check_status_later')}`,
        {
          key: 'passwordStrengthError',
          variant: 'warning',
        }
      );
    }
  }, [siteDetails, forceValidationRecheck.status, setActiveStep]);

  const records = validationRecords.map((record: VerificationRecord) => ({
    ...record,
    name:
      baseDomain === record.name ? (subdomain ?? '@') : record.name?.replace(`.${baseDomain}`, ''),
  }));

  const instructions = records.map(record => ({
    key: 'ready_to_go_live_verification_new',
    data: { type: record.type?.toUpperCase(), name: record.name, value: record.value },
    components: {
      copyNameBubble: <CopyValueCard value={record.name} />,
      copyValueBubble: <CopyValueCard value={record.value} />,
    },
  }));

  return (
    <>
      <WizardFormContent>
        <Stack gap={4} padding={4}>
          <Typography variant="body1" fontWeight="600">
            {t('configure_ssl')}
          </Typography>
          <Typography variant="body2" color="greys.500">
            {t('configure_free_ssl_description')}
          </Typography>
          <NoteCard>
            <Trans
              i18nKey="click_the_copy_icon_description"
              components={{
                copyIcon: <Copy />,
              }}
            />
          </NoteCard>
          <SetupInstructions instructions={instructions} testId="configure-ssl-step" />
          <DNSRecordsTable records={records} hideTTLColumn label="SSL Records Table" />
          <NoteCard>
            <Trans
              i18nKey="self_signed_certificate_step_1"
              components={[
                <LoadingButton
                  key="1"
                  loading={updateSSLValidation.isPending}
                  sx={{ verticalAlign: 'baseline' }}
                  onClick={async () => {
                    await updateSSLValidation.mutateAsync({
                      validation_method: 'http',
                    });
                    setActiveStep(3);
                  }}
                />,
              ]}
            />
          </NoteCard>
        </Stack>
      </WizardFormContent>
      <WizardFormActions>
        <Stack gap={3} direction="row" flex="none">
          <LoadingButton
            size="large"
            variant="contained"
            onClick={async () => {
              await forceValidationRecheck.mutateAsync();
            }}
            loading={forceValidationRecheck.isPending}
          >
            {t('continue')}
          </LoadingButton>
          <Button size="large" onClick={onClose}>
            {t('cancel')}
          </Button>
        </Stack>
      </WizardFormActions>
    </>
  );
}

function ChangeDomainName({
  siteDetails,
  setActiveStep,
  onClose,
  onFormDirty,
}: StepProps & {
  readonly onFormDirty?: (state: boolean) => unknown;
}) {
  interface DomainFormValue {
    domain: string;
  }

  const methods = useForm<DomainFormValue>({
    defaultValues: {
      domain: '',
    },
    mode: 'onChange',
  });
  const { handleSubmit, formState } = methods;

  useEffect(() => {
    onFormDirty?.(formState.isDirty);
  }, [formState.isDirty]);

  const setMainDomain = useSetMainDomain(siteDetails?.id?.toString() ?? '');

  const { t } = useTranslation();

  const onSubmit = async (data: DomainFormValue) => {
    await setMainDomain.mutateAsync({
      domain: `${data.domain.toLowerCase()}`,
    });
    setActiveStep(2);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <WizardFormContent>
          <Stack gap={4} p={4}>
            <Typography variant="body1" fontWeight="600">
              {t('change_domain_name')}
            </Typography>
            <Stack gap={2}>
              <Typography color="greys.500">{t('enter_your_domain_name_new')}</Typography>

              <Stack gap={4}>
                <TextField
                  fullWidth
                  label=""
                  defaultValue=""
                  name="domain"
                  disabled={setMainDomain.isPending}
                  placeholder={t('enter_your_domain_name_placeholder')}
                  rules={{
                    required: true,
                    validate: domain => {
                      return isFQDN(domain);
                    },
                  }}
                  formatValue={val => {
                    if (val.includes('https://www.')) {
                      return val.replace('https://www.', '');
                    } else if (val.includes('http://www.')) {
                      return val.replace('http://www.', '');
                    } else if (val.includes('https://')) {
                      return val.replace('https://', '');
                    } else if (val.includes('http://')) {
                      return val.replace('http://', '');
                    }
                    return val;
                  }}
                  startAdornment={
                    <InputAdornment
                      position="start"
                      disableTypography
                      component="button"
                      variant="outlined"
                      sx={theme => ({
                        fontSize: '12px',
                        fontWeight: 600,
                        padding: '4px 8px',
                        borderRadius: '5px',
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.greys[800],
                      })}
                    >
                      {t('http_optional_s')}
                    </InputAdornment>
                  }
                />
              </Stack>
            </Stack>
          </Stack>
        </WizardFormContent>
        <WizardFormActions>
          <Stack gap={3} direction="row" flex="none">
            <LoadingButton
              size="large"
              disabled={setMainDomain.isPending || !formState.isValid}
              onClick={async () => {
                await handleSubmit(onSubmit)();
              }}
              loading={setMainDomain.isPending}
              variant="contained"
            >
              {t('continue')}
            </LoadingButton>
            <Button size="large" onClick={onClose}>
              {t('cancel')}
            </Button>
          </Stack>
        </WizardFormActions>
      </form>
    </FormProvider>
  );
}

function ReadyToGoLiveDialog({
  siteDetails,
  onClose,
  initialActiveStep,
}: {
  readonly siteDetails: SiteDetail;
  readonly onClose: () => unknown;
  readonly initialActiveStep: number;
}) {
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = React.useState<number>(initialActiveStep);
  const [isFormDirty, setIsFormDirty] = React.useState(false);

  const StepComponent = [ChangeDomainName, ConfigureSSL, UpdateDns][activeStep - 1];

  return (
    <WizardDialog
      activeStep={activeStep}
      onClose={onClose}
      name={'ready-to-go-live'}
      title={
        <Stack direction="row" gap={4} alignItems="center">
          <Box
            width="20px"
            height="20px"
            color="greys.500"
            sx={{
              '& svg': {
                width: '100%',
              },
            }}
          >
            <RocketShipSolid />
          </Box>
          <Stack spacing={1}>
            <Typography variant="body1" color="greys.900" fontWeight="600">
              {t('ready_to_go_live')}
            </Typography>
            <Typography variant="body2" color="greys.500">
              {t('ready_to_go_live_description_new')}
            </Typography>
          </Stack>
        </Stack>
      }
      steps={[
        {
          index: 1,
          title: t('ready_to_go_live_wizard_step_one_title'),
          description: t('ready_to_go_live_wizard_step_one_description'),
        },
        {
          index: 2,
          title: t('ready_to_go_live_wizard_step_two_title'),
          description: t('ready_to_go_live_wizard_step_two_description'),
        },
        {
          index: 3,
          title: t('ready_to_go_live_wizard_step_three_title'),
          description: t('ready_to_go_live_wizard_step_three_description'),
        },
      ]}
      stepNode={
        <StepComponent
          siteDetails={siteDetails}
          setActiveStep={setActiveStep}
          onClose={onClose}
          onFormDirty={setIsFormDirty}
        />
      }
      preventAccidentalClose={isFormDirty || activeStep > 1}
    />
  );
}

export default function ReadyToGoLiveCard({ siteDetails }: { readonly siteDetails?: SiteDetail }) {
  const { sslStatus, dnsProvider, hostnameStatus, validationMethod, showGoLive } =
    getMainDomainInfo(siteDetails);
  const [open, setOpen] = React.useState<boolean>(!!sslStatus);

  const { t } = useTranslation();

  if (!siteDetails || (siteDetails && siteDetails.production) || showGoLive === 0) {
    return null;
  }

  let initialActiveStep = 1;

  if (validationMethod === 'http' || (sslStatus === 'active' && hostnameStatus === 'active')) {
    initialActiveStep = 3;
  }

  if ((sslStatus && sslStatus !== 'active') || (hostnameStatus && hostnameStatus !== 'active')) {
    initialActiveStep = 2;
  }

  if (dnsProvider === DNS_PROVIDER_CLOUDFLARE && sslStatus === 'active') {
    initialActiveStep = 3;
  }

  const openText = initialActiveStep > 1 ? t('continue') : t('get_started');

  return (
    <section>
      <ActionCard
        paletteColor="greys"
        icon={<RocketShipSolid />}
        title={
          initialActiveStep > 1
            ? t('ready_to_go_live_complete_title', { step: initialActiveStep - 1 })
            : t('ready_to_go_live')
        }
        description={
          initialActiveStep > 1
            ? t('ready_to_go_live_complete_description')
            : t('ready_to_go_live_description')
        }
        actions={
          <Button color="primary" onClick={() => setOpen(!open)} variant="contained">
            {openText}
          </Button>
        }
      />
      {open ? (
        <ReadyToGoLiveDialog
          initialActiveStep={initialActiveStep}
          onClose={() => setOpen(false)}
          siteDetails={siteDetails}
        />
      ) : null}
    </section>
  );
}
