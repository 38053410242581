import { linkHelper } from 'linkHelper';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Suspense, lazy } from 'react';

const SiteTemplateList = lazy(() => import('../../partial/SiteTemplateList'));
const ViewSiteTemplate = lazy(() => import('../../partial/ViewSiteTemplate'));

export default function SiteTemplates() {
  return (
    <Routes>
      <Route
        index
        element={
          <Suspense>
            <SiteTemplateList />
          </Suspense>
        }
      />
      <Route
        path={linkHelper.newDesign.settings.siteTemplates.viewTemplate.path}
        element={
          <Suspense>
            <ViewSiteTemplate />
          </Suspense>
        }
      />
      <Route
        path="*"
        element={<Navigate to={linkHelper.newDesign.settings.siteTemplates.getLink()} replace />}
      />
    </Routes>
  );
}
