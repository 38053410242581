import { useEffect, useMemo, useState } from 'react';
import { Avatar, Skeleton } from '@mui/material';

interface GravatarProps {
  readonly name: string;
  readonly email?: string;
  readonly size?: 'xs' | 's' | 'm' | 'l' | 'xxl';
  readonly isLoading?: boolean;
}

const colors = [
  'blues.500',
  'greens.500',
  'oranges.500',
  'reds.500',
  'blues.700',
  'greens.700',
  'oranges.700',
  'reds.700',
];

const sizeMap = {
  xs: '16px',
  s: '24px',
  m: '28px',
  l: '32px',
  xxl: '64px',
};

const fontSizeMap = {
  xs: '10px',
  s: '12px',
  m: '12px',
  l: '14px',
  xxl: '28px',
};

const hashName = (name: string) => {
  let hash = 0;
  for (const char of name) {
    hash += char.charCodeAt(0);
  }
  return hash;
};

export const Gravatar = ({ name, email, size = 'l', isLoading }: GravatarProps) => {
  const [url, setUrl] = useState<string | undefined>(undefined);
  const color = useMemo(() => colors[hashName(name) % colors.length], [name]);

  useEffect(() => {
    (async () => {
      if (!email) {
        return;
      }
      const data = new TextEncoder().encode(email);
      const digest = await crypto.subtle.digest('SHA-256', data);
      const hash = Array.from(new Uint8Array(digest))
        .map(b => b.toString(16).padStart(2, '0'))
        .join('');
      setUrl(`https://www.gravatar.com/avatar/${hash}?d=404`);
    })();
  }, [email]);

  const initials = name
    .split(' ')
    .map((name: string) => name[0])
    .join('')
    .toUpperCase();

  if (isLoading) {
    return (
      <Skeleton
        sx={{
          borderRadius: '50px',
        }}
        variant="rounded"
        width={sizeMap[size]}
        height={sizeMap[size]}
      />
    );
  }

  return (
    <Avatar
      alt={name}
      src={url}
      sx={{
        backgroundColor: color,
        width: sizeMap[size],
        height: sizeMap[size],
        fontSize: fontSizeMap[size],
      }}
    >
      {initials}
    </Avatar>
  );
};
