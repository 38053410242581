import React, { useEffect } from 'react';
import { Box, Card, CardHeader, CardContent, useTheme, Stack, Typography } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { useSiteBandwidthUsageNew } from 'api/siteMetrics';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DurationSelect } from './DurationSelect';
import { GraphCard } from 'component/base/GraphCard';
import { EmptyState } from '../base/EmptyState';
import { IconWrapper } from '../base/IconWrapper';
import BarChart from 'component/new_design/icons/BarChart.svg?react';
import Circle from 'component/new_design/icons/Circle.svg?react';

interface SiteActivityCardProps {
  readonly title: string;
  readonly onDurationChange?: (duration: string) => void;
}

type Metric = 'totalRequests' | 'edgeRequests' | 'originRequests' | 'wafEvents';

const loadingValues = {
  totalRequests: [4, 5, 4.5],
  edgeRequests: [2, 2, 2.5],
  originRequests: [3, 3.5, 3.2, 3.5],
  wafEvents: [0.5, 0.7, 0.5, 0.6],
};

const loadingPointCount = {
  '1h': 10,
  '6h': 12,
  '12h': 12,
  '24h': 24,
  '72h': 18,
  '7d': 14,
  '30d': 30,
};

// TODO use API generated type for data
const formatBottomAxis = (data: any, duration: string, locale: string) => (value: number) => {
  const time = data?.result[value]?.time;
  if (!time) {
    return '';
  }
  const date = new Date(time!);
  switch (duration) {
    case '1h':
    case '6h':
    case '12h':
    case '24h':
      return date.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      });
    case '72h':
    case '7d':
      return date.toLocaleTimeString(locale, {
        weekday: 'short',
        hour: '2-digit',
      });
    case '30d':
      return date.toLocaleDateString(locale, {
        month: 'short',
        day: 'numeric',
      });
  }
};

export function SiteActivityCard({ title, onDurationChange }: SiteActivityCardProps) {
  const theme = useTheme();
  const { siteId = '' } = useParams<{ siteId: string }>();

  const [duration, setDuration] = React.useState<string>('24h');
  const { isLoading, data } = useSiteBandwidthUsageNew(siteId, duration);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (onDurationChange) {
      onDurationChange(duration);
    }
  }, [duration, onDurationChange]);

  const getLineData = (key: Metric) => {
    return data
      ? data.result.map((datum, i) => ({
          y: datum[key],
          x: i,
        }))
      : new Array(loadingPointCount[duration as keyof typeof loadingPointCount])
          .fill(null)
          .map((_, i) => ({
            y: loadingValues[key][i % loadingValues[key].length],
            x: i,
          }));
  };

  const getLineColor = (key: Metric) => {
    const colors = {
      totalRequests: theme.palette.oranges[500],
      edgeRequests: theme.palette.greens[500],
      originRequests: theme.palette.reds[500],
      wafEvents: theme.palette.blues[500],
      loading: theme.palette.greys[100],
    };

    return isLoading ? colors.loading : colors[key];
  };

  const emptyState = data?.result.length === 0;

  return (
    <Card component="section">
      <CardHeader
        action={<DurationSelect value={duration} onChange={setDuration} />}
        title={title}
      />
      <CardContent>
        <Box
          sx={{
            display: 'grid',
            gap: 2,
            gridTemplateColumns: ['1fr', 'repeat(2, 1fr)', 'repeat(4, 1fr)'],
          }}
        >
          <GraphCard
            color="oranges.500"
            label={t('total_requests')}
            loading={isLoading}
            value={data?.metadata.totals.totalRequests || 0}
          />
          <GraphCard
            color="greens.500"
            label={t('served_by_edge')}
            loading={isLoading}
            value={data?.metadata.totals.edgeRequests || 0}
            valuePercentage={data?.metadata.percentages.edgeRequests || 0}
          />
          <GraphCard
            color="reds.500"
            label={t('served_by_origin')}
            loading={isLoading}
            value={data?.metadata.totals.originRequests || 0}
            valuePercentage={data?.metadata.percentages.originRequests || 0}
          />
          <GraphCard
            color="blues.500"
            label={t('waf_events')}
            loading={isLoading}
            value={data?.metadata.totals.wafEvents || 0}
          />
        </Box>

        <Box
          sx={{
            height: '300px',
            svg: {
              line: {
                stroke: 'greys.100',
              },
              'line:not(:first-of-type)': {
                strokeDasharray: '5 5',
              },
              path: {
                transition: 'stroke 1s',
              },
              'path[style*="mix"]': {
                opacity: isLoading ? 1 : 0,
                transition: 'opacity 1s',
              },
            },
          }}
        >
          {emptyState ? (
            <EmptyState
              title={duration === '30d' ? t('no_data_yet') : t('no_results_during_this_timeframe')}
              icon={<BarChart />}
            />
          ) : (
            <ResponsiveLine
              key={duration}
              data={[
                { id: t('total_requests'), data: getLineData('totalRequests') },
                { id: t('served_by_edge'), data: getLineData('edgeRequests') },
                { id: t('served_by_origin'), data: getLineData('originRequests') },
                { id: t('waf_events'), data: getLineData('wafEvents') },
              ]}
              colors={[
                getLineColor('totalRequests'),
                getLineColor('edgeRequests'),
                getLineColor('originRequests'),
                getLineColor('wafEvents'),
              ]}
              lineWidth={2}
              xScale={{ type: 'linear' }}
              yScale={{ type: 'linear', min: 0, max: 'auto' }}
              axisBottom={{
                format: formatBottomAxis(data, duration, i18n.language),
                tickSize: 0,
              }}
              axisLeft={{
                tickSize: 0,
                format: value => (isLoading ? '' : value),
              }}
              enablePoints={false}
              enableGridX={false}
              curve="catmullRom"
              margin={{ top: 40, right: 40, bottom: 54, left: 60 }}
              defs={[
                {
                  colors: [
                    { color: theme.palette.greys[100], offset: 0 },
                    { color: theme.palette.greys[100], offset: 80, opacity: 0 },
                  ],
                  id: 'gradientA',
                  type: 'linearGradient',
                },
              ]}
              fill={[
                {
                  id: 'gradientA',
                  match: '*',
                },
              ]}
              enableArea
              pointLabel="yFormatted"
              enableSlices={isLoading ? false : 'x'}
              sliceTooltip={({ slice }) => {
                return (
                  <Stack
                    sx={{
                      p: 2.5,
                      backgroundColor: 'greys.900',
                      borderRadius: 'var(--border-radius-m)',
                      minWidth: '200px',
                      gap: 2,
                    }}
                    divider={<Box sx={{ height: '1px', backgroundColor: 'greys.800' }} />}
                  >
                    <Typography
                      color="common.white"
                      variant="caption"
                      textTransform="uppercase"
                      fontWeight={700}
                    >
                      {formatBottomAxis(
                        data,
                        duration,
                        i18n.language
                      )(Number(slice.points[0].data.xFormatted))}
                    </Typography>
                    <Stack gap={1}>
                      {slice.points.toReversed().map(point => (
                        <Stack key={point.id} direction="row" alignItems="center" gap={2}>
                          <IconWrapper size="xxs" color={point.color}>
                            <Circle />
                          </IconWrapper>
                          <Typography variant="body2" color="greys.300">
                            {point.serieId}
                          </Typography>
                          <Typography variant="body2" color="common.white" ml="auto">
                            {point.data.yFormatted}
                          </Typography>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                );
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
}
