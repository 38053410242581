import { Typography, Skeleton, Stack } from '@mui/material';
import { useSitesNew, useSitesUsersNew } from 'api/site';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Select } from '../base/forms/Select';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { getIsWpSiteControl } from 'utils';
import { formatDomainName } from 'utils/formatting';

export function SiteSelectorMenu() {
  const { siteId } = useParams<{ siteId: string }>();
  const isWpSiteControl = getIsWpSiteControl();

  const paginationState = usePaginatedListState(
    { perPageOptions: [100], urlStatePrefix: 'site-selector' },
    { perPage: 100, sortBy: 'domain', sortDirection: 'asc' }
  );

  const sitesData = useSitesNew(paginationState, { enabled: !isWpSiteControl });
  const userSitesData = useSitesUsersNew(paginationState, { enabled: isWpSiteControl });
  const { data, isLoading } = isWpSiteControl ? userSitesData : sitesData;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const options =
    data?.data.result?.map(site => ({
      value: site.id || 0,
      label: (
        <Stack>
          <Typography variant="body2" fontWeight={600}>
            {formatDomainName(site.domain)}
          </Typography>
          <Typography variant="caption" color="greys.500">
            {site.label}
          </Typography>
        </Stack>
      ),
    })) || [];

  const onChange = (value: number) => {
    navigate(pathname.replace(String(siteId), String(value)));
  };

  if (isLoading) {
    return <Skeleton variant="rounded" height="40px" width="200px" />;
  }

  return (
    <Select
      sx={{
        padding: '0 2.25rem 0 0 !important',
        outline: 'none !important',
        background: 'none !important',
        '.MuiSelect-select': {
          padding: '0 !important',
          transition: 'color 0.2s',
          '&[aria-expanded="true"]': {
            color: 'blues.500',
            '& ~ .MuiSelect-icon': {
              color: 'blues.500',
            },
          },
        },
        '.MuiSelect-icon': {
          width: '1.5rem',
          height: '1.5rem',
          transform: 'translateY(-0.25rem)',
        },
      }}
      options={options}
      value={Number(siteId)}
      onChange={onChange}
      renderValue={value => (
        <Typography variant="h2" component="span" color="inherit">
          {formatDomainName(data?.data.result?.find(site => site.id === value)?.domain)}
        </Typography>
      )}
    />
  );
}
