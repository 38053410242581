import { Stack } from '@mui/material';
import { getSiteWpStatus, SiteDetail } from 'api/site';
import { useTranslation } from 'react-i18next';
import ActionCard from './ActionCard';
import AlertTriangle from 'component/new_design/icons/AlertTriangle.svg?react';

interface SiteStatusProps {
  readonly siteDetails?: SiteDetail;
}

export const SiteStatus = ({ siteDetails }: SiteStatusProps) => {
  const { t } = useTranslation();

  const wpStatus = getSiteWpStatus(siteDetails?.id, siteDetails && siteDetails.site_type !== 1);

  const data = wpStatus.data?.data?.result?.wordpress_status;

  const hasCriticalError = data?.operational_status === 'critical_error';
  const errorMessage = data?.error;

  return hasCriticalError ? (
    <ActionCard
      paletteColor="reds"
      icon={<AlertTriangle />}
      title={t('critical_error')}
      description={
        <Stack gap={1}>
          <div>{t('wp_critical_error_description')}</div>
          <div>{errorMessage}</div>
        </Stack>
      }
    />
  ) : null;
};
