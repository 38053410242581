import { Box, Button, Card, CardContent, CardHeader } from '@mui/material';
import { ReportingList } from './ReportingList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportingListDialog } from './ReportingListDialog';
import { getSpacing } from 'component/new_design/theme/palettes/utils';
import { ReportingListItem } from './ReportingOverview';

interface ReportingListCardProps {
  readonly title: string;
  readonly data: ReportingListItem[];
}

export const LIST_ITEM_COUNT = 5;

export const ReportingListCard = ({ title, data }: ReportingListCardProps) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const needsSeeAllDialog = data.length > LIST_ITEM_COUNT;
  const cardData = needsSeeAllDialog ? data.slice(0, LIST_ITEM_COUNT) : data;

  return (
    <>
      <Card component="section">
        <CardHeader
          title={title}
          action={
            needsSeeAllDialog ? (
              <Button variant="contained" color="secondary" onClick={() => setDialogOpen(true)}>
                {t('see_all')}
              </Button>
            ) : null
          }
        />
        <Box
          sx={{
            margin: getSpacing(4, 2, 2),
            height: `calc(var(--reporting-list-item-height) * ${LIST_ITEM_COUNT})`,
          }}
        >
          <ReportingList data={cardData} />
        </Box>
      </Card>
      {dialogOpen ? (
        <ReportingListDialog title={title} data={data} onClose={() => setDialogOpen(false)} />
      ) : null}
    </>
  );
};
