import { Typography } from '@mui/material';
import { ReactNode } from 'react';

interface LabelProps {
  readonly htmlFor?: string;
  readonly id?: string;
  readonly children: ReactNode;
}

export const Label = ({ children, htmlFor, id }: LabelProps) => (
  <Typography variant="body2" color="greys.500" pb={2} component="label" htmlFor={htmlFor} id={id}>
    {children}
  </Typography>
);
