import { Tooltip } from '@mui/material';
import { differenceInDays, intlFormatDistance } from 'date-fns';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatLocaleDate } from 'utils/dateFormat';
import { EMPTY_VALUE_PLACEHOLDER } from 'utils/formatting';

interface TimeCellProps {
  readonly value: any;
  readonly enableRelativeTime?: boolean;
}

const RELATIVE_TIME_THRESHOLD_DAYS = 1;
const REFRESH_INTERVAL = 60_000;

export const TimeCell = ({ value, enableRelativeTime }: TimeCellProps) => {
  const { i18n } = useTranslation();
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    if (!enableRelativeTime) return;

    const interval = setInterval(() => setNow(new Date()), REFRESH_INTERVAL);

    return () => clearInterval(interval);
  }, [enableRelativeTime]);

  const date = new Date(value);

  if (isNaN(date.getTime())) {
    return EMPTY_VALUE_PLACEHOLDER;
  }

  const useRelativeTime =
    enableRelativeTime && differenceInDays(now, date) < RELATIVE_TIME_THRESHOLD_DAYS;

  if (!useRelativeTime) {
    return formatLocaleDate({ locale: i18n.language, value: date });
  }

  if (useRelativeTime) {
    return (
      <Tooltip title={formatLocaleDate({ locale: i18n.language, value: date })}>
        <time dateTime={date.toISOString()}>
          {intlFormatDistance(date, now, { locale: i18n.language })}
        </time>
      </Tooltip>
    );
  }
};
