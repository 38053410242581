import { Box, Stack, Typography } from '@mui/material';
import Page from 'component/new_design/layout/Page';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { linkHelper } from 'linkHelper';
import { Suspense } from 'react';
import Users from './Settings/Users';
import SiteTemplates from './Settings/SiteTemplates';
import Navigation, { NavigationItem } from '../partial/Navigation';
// icons
import UserDuotone from '../icons/UserDuotone';
import CreditCardDuotone from '../icons/CreditCardDuotone';
import UserThreeDuotone from '../icons/UserThreeDuotone';
import MonitorDuotone from '../icons/MonitorDuotone';
// mobile menu icons
import User from '../icons/User.svg?react';
import UserThree from '../icons/UserThree.svg?react';
import Monitor from '../icons/Monitor.svg?react';
import CreditCard from '../icons/CreditCard.svg?react';

export default function Settings() {
  const { t } = useTranslation();
  const location = useLocation();

  const navigationMenuItems: NavigationItem[] = [
    {
      solidIcon: <User />,
      duotoneIcon: UserDuotone,
      title: t('account_and_security'),
      to: linkHelper.newDesign.settings.accountAndSecurity.getLink(),
    },
    {
      solidIcon: <CreditCard />,
      duotoneIcon: CreditCardDuotone,
      title: t('billing'),
      to: linkHelper.newDesign.settings.billing.getLink(),
    },
    {
      solidIcon: <UserThree />,
      duotoneIcon: UserThreeDuotone,
      title: t('users'),
      to: linkHelper.newDesign.settings.users.getLink(),
    },
    {
      solidIcon: <Monitor />,
      duotoneIcon: MonitorDuotone,
      title: t('site_templates'),
      to: linkHelper.newDesign.settings.siteTemplates.getLink(),
    },
  ];

  const isTopLevelRoute = navigationMenuItems.some(item => item.to === location.pathname);

  return (
    <Page>
      <Stack gap={6}>
        {isTopLevelRoute ? <Typography variant="h2">{t('settings')}</Typography> : null}
        {isTopLevelRoute ? <Navigation items={navigationMenuItems} /> : null}
        <Routes>
          <Route
            path={linkHelper.newDesign.settings.accountAndSecurity.path}
            element={
              <Suspense>
                <Box />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.settings.billing.path}
            element={
              <Suspense>
                <Box />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.settings.users.path}
            element={
              <Suspense>
                <Users />
              </Suspense>
            }
          />
          <Route
            path={linkHelper.newDesign.settings.siteTemplates.path}
            element={
              <Suspense>
                <SiteTemplates />
              </Suspense>
            }
          />

          <Route
            path="*"
            element={
              <Navigate to={linkHelper.newDesign.settings.accountAndSecurity.getLink()} replace />
            }
          />
        </Routes>
      </Stack>
    </Page>
  );
}
