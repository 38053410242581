import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { Table } from 'component/new_design/base/Table';
import { EmptyState } from 'component/new_design/base/EmptyState';
import { Button, Typography } from '@mui/material';
import {
  Plugin,
  useChangeActiveStatus,
  useDeletePlugin,
  useInstalledPluginList,
  useUpdatePlugin,
} from 'api/plugins';
import { useParams } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { PluginStatusCell } from './PluginStatusCell';
import { ConfirmationDialog } from 'component/new_design/base/dialogs/ConfirmationDialog';
import { useSnackbar } from 'component/hooks/useSnackbar';
import PluginIcon from 'component/new_design/icons/PluginAddon.svg';
import Plus from 'component/new_design/icons/Plus.svg?react';
import Play from 'component/new_design/icons/Play.svg?react';
import Stop from 'component/new_design/icons/Stop.svg?react';
import UploadUp from 'component/new_design/icons/UploadUp.svg?react';
import Delete from 'component/new_design/icons/Delete.svg?react';
import { useCheckUserRole } from 'component/hooks/useCheckUserRole';

export function PluginsTable() {
  const { siteId } = useParams<{ siteId: string }>();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const tableState = usePaginatedListState();

  const plugins = useInstalledPluginList(tableState, siteId!);

  const deletePlugin = useDeletePlugin(siteId!);
  const changePluginActiveStatus = useChangeActiveStatus(siteId!);
  const updatePlugin = useUpdatePlugin(siteId!);

  const [pluginNameToDelete, setPluginNameToDelete] = useState<string | null>(null);
  const [pluginNameToActivate, setPluginNameToActivate] = useState<string | null>(null);
  const [pluginNameToDeactivate, setPluginNameToDeactivate] = useState<string | null>(null);
  const [pluginNameToUpdate, setPluginNameToUpdate] = useState<string | null>(null);

  const showActions = !useCheckUserRole('billing_admin');

  const columns: TableColumnType<Plugin>[] = [
    {
      label: t('name'),
      key: 'name',
      renderValue: plugin => (
        <Typography
          variant="body2"
          fontWeight={600}
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {plugin.title}
        </Typography>
      ),
      sortable: true,
      minWidth: '30em',
    },
    {
      label: t('version'),
      key: 'version',
      renderValue: plugin => (
        <Typography variant="body2" color="colors.500">
          {plugin.version}
        </Typography>
      ),
      sortable: true,
      width: 0.3,
    },
    {
      label: t('status'),
      key: 'status',
      renderValue: plugin => <PluginStatusCell plugin={plugin} />,
      sortable: true,
      width: 0.3,
    },
  ];

  const rowActions: TableRowActionType<Plugin>[] = showActions
    ? [
        {
          label: t('activate'),
          onClick: plugin => setPluginNameToActivate(plugin.name || null),
          hidden: plugin => plugin.status === 'active',
          icon: <Play />,
        },
        {
          label: t('deactivate'),
          onClick: plugin => setPluginNameToDeactivate(plugin.name || null),
          hidden: plugin => plugin.status === 'must-use' || plugin.status === 'inactive',
          icon: <Stop />,
        },
        {
          label: t('update'),
          onClick: plugin => setPluginNameToUpdate(plugin.name || null),
          icon: <UploadUp />,
        },
        {
          label: t('delete'),
          onClick: plugin => setPluginNameToDelete(plugin.name || null),
          color: 'reds.500',
          hidden: plugin => plugin.status === 'must-use',
          icon: <Delete />,
        },
      ]
    : [];

  const handleActivateConfirmed = async () => {
    if (!pluginNameToActivate) {
      throw new Error('Plugin to activate is not set');
    }

    await changePluginActiveStatus.mutateAsync({
      plugin: pluginNameToActivate,
      status: 'activate',
    });

    enqueueSnackbar(t('plugin_activated_successfully'), { variant: 'success' });
    setPluginNameToActivate(null);
  };

  const handleDeactivateConfirmed = async () => {
    if (!pluginNameToDeactivate) {
      throw new Error('Plugin to deactivate is not set');
    }

    await changePluginActiveStatus.mutateAsync({
      plugin: pluginNameToDeactivate,
      status: 'deactivate',
    });

    enqueueSnackbar(t('plugin_deactivated_successfully'), { variant: 'success' });
    setPluginNameToDeactivate(null);
  };

  const handleUpdateConfirmed = async () => {
    if (!pluginNameToUpdate) {
      throw new Error('Plugin to update is not set');
    }

    await updatePlugin.mutateAsync({
      plugin: pluginNameToUpdate,
      update: true,
    });

    enqueueSnackbar(t('plugin_updated_successfully'), { variant: 'success' });
    setPluginNameToDelete(null);
  };

  const handleDeleteConfirmed = async () => {
    if (!pluginNameToDelete) {
      throw new Error('Plugin to delete is not set');
    }

    await deletePlugin.mutateAsync({
      plugins: pluginNameToDelete,
    });

    enqueueSnackbar(t('plugin_deleted_successfully'), { variant: 'success' });
    setPluginNameToDelete(null);
  };

  const getPluginTitle = (pluginName: string) => {
    return plugins.data?.data.result?.find(plugin => plugin.name === pluginName)?.title;
  };

  return (
    <>
      <Table
        title={t('installed_plugins')}
        label={t('installed_plugins')}
        columns={columns}
        rowActions={rowActions}
        data={plugins.data?.data.result || []}
        totalRowCount={plugins.data?.data.metadata?.total || 0}
        isLoading={plugins.isLoading}
        state={tableState}
        searchPlaceholder={t('search_plugins')}
        enableSearch
        enablePagination
        emptyState={
          <EmptyState
            icon={<PluginIcon />}
            title={t('no_plugins_yet')}
            description={t('no_plugins_description')}
            action={
              <Button
                href={linkHelper.newDesign.sites.siteDetails.plugins.discover.getLink({
                  siteId: siteId || '',
                })}
                size="medium"
                variant="contained"
                color="primary"
                startIcon={<Plus />}
              >
                {t('install_plugin')}
              </Button>
            }
          />
        }
        rowIdKey="name"
      />

      {pluginNameToDelete ? (
        <ConfirmationDialog
          title={t('delete_plugin')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_delete"
              values={{ name: getPluginTitle(pluginNameToDelete) }}
            />
          }
          confirmColor="error"
          confirmText={t('delete')}
          onConfirm={handleDeleteConfirmed}
          onClose={() => setPluginNameToDelete(null)}
        />
      ) : null}
      {pluginNameToActivate ? (
        <ConfirmationDialog
          title={t('activate_plugin')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_activate"
              values={{ name: getPluginTitle(pluginNameToActivate) }}
            />
          }
          confirmText={t('activate')}
          onConfirm={handleActivateConfirmed}
          onClose={() => setPluginNameToActivate(null)}
        />
      ) : null}
      {pluginNameToDeactivate ? (
        <ConfirmationDialog
          title={t('deactivate_plugin')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_deactivate"
              values={{ name: getPluginTitle(pluginNameToDeactivate) }}
            />
          }
          confirmText={t('deactivate')}
          onConfirm={handleDeactivateConfirmed}
          onClose={() => setPluginNameToDeactivate(null)}
        />
      ) : null}
      {pluginNameToUpdate ? (
        <ConfirmationDialog
          title={t('update_plugin')}
          description={
            <Trans
              i18nKey="are_you_sure_you_want_to_update"
              values={{ name: getPluginTitle(pluginNameToUpdate) }}
            />
          }
          confirmColor="error"
          confirmText={t('update')}
          onConfirm={handleUpdateConfirmed}
          onClose={() => setPluginNameToUpdate(null)}
        />
      ) : null}
    </>
  );
}
