import { PaginatedListState } from 'component/hooks/usePaginatedListState';

// Combines category filter and search param into a single query parameter for the API
export const convertCategoryAndSearchToQueryParam = (state: PaginatedListState) => {
  return {
    ...state,
    params: {
      ...state.params,
      filters: { query: `${state.params.filters.category || ''} ${state.params.search}`.trim() },
      search: '',
    },
  };
};
