import { Stack, Link, Typography, Button } from '@mui/material';
import { TableColumnType, TableRowType, TableRowActionType } from './types';
import { DropdownAction, DropdownMenu } from 'component/new_design/base/DropdownMenu';

export const actionsColumn: NonNullable<TableColumnType<TableRowType>> = {
  label: 'Actions',
  align: 'right',
  width: 0,
  minWidth: '40px',
};

interface Props<T extends TableRowType> {
  readonly row: T;
  readonly actions: (TableRowActionType<T> | null)[];
}

export const TableRowActions = <T extends TableRowType>({ row, actions }: Props<T>) => {
  const isActionVisible = (action: Props<T>['actions'][number]): action is TableRowActionType<T> =>
    !!action && !action.hidden?.(row);

  const dropdownActions: DropdownAction[] = actions
    .filter(isActionVisible)
    .filter(item => item.inline !== true)
    .map((action: TableRowActionType<T>) => ({
      ...action,
      onClick: () => action.onClick(row),
    }));

  const inlineDropdownActions: DropdownAction[] = actions
    .filter(isActionVisible)
    .filter(item => item.inline === true)
    .map((action: TableRowActionType<T>) => ({
      ...action,
      onClick: () => action.onClick(row),
    }));

  return (
    <Stack direction="row" sx={{ my: '-10px' }} gap={4}>
      <Stack direction="row" gap={3}>
        {inlineDropdownActions.map(item => (
          <Button
            variant="text"
            key={item.label}
            onClick={() => {
              item.onClick();
            }}
          >
            {item.label}
          </Button>
        ))}
      </Stack>
      <DropdownMenu actions={dropdownActions} buttonProps={{ variant: 'text', color: 'inherit' }} />
    </Stack>
  );
};
